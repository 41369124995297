import HttpService from './HttpService'

const _http = new HttpService()
export default {
  showByPermission: (_permission) => {
    return _http.get(`/api/report-iframe-permission/getByPermission?permission=${_permission}`)
  },
  show: (page, search = null) => {
    let _parans = `?page=${page - 1}&asc`;
    if (search) {
      _parans += `&search=${search}`;
    }
    return _http.get(`/api/report-iframe-permission${_parans}`)
  },
  find: (id) => {
    return _http.get(`/api/report-iframe-permission/${id}`)
  },
  create: (payload) => {
    return _http.post('/api/report-iframe-permission', payload)
  },
  update: (payload) => {
    return _http.put('/api/report-iframe-permission', payload)
  },
  delete: (id) => {
    return _http.delete(`/api/report-iframe-permission/${id}`)
  }
}