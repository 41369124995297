<template>
  <b-form>
    <div class="text-right">
      <b class="d-inline-block mb-25">Tempo de Atualização:</b>
      <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret right>
        <template #button-content>
          <feather-icon
            size="21"
            :class="timerSelected.value != 0 ? 'text-info' : 'text-primary'"
            icon="ClockIcon"
          />
        </template>
        <b-dropdown-item
          v-for="option in optionsTimer"
          :key="option.value"
          @click="onChangeTimer(option)"
        >
          {{ option.label }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <b-row>
      <b-col cols="12" md="12">
        <iframe
          v-if="isReady"
          :src="getDashboard(appConfig.layout.skin, timerSelected)"
          frameborder="0"
          v-resize="{ log: true }"
          width="100%"
          allowtransparency
        ></iframe>
        <span v-if="msg">{{ msg }}</span>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import jwt from "jsonwebtoken";
import { mapState } from "vuex";
import _ipermission from "@/services/Iframe-permission-service";
import iframeResize from "iframe-resizer/js/iframeResizer";

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  computed: {
    ...mapState(["appConfig"]),
  },
  directives: {
    resize: {
      bind: function (el, { value = {} }) {
        el.addEventListener("load", () => iframeResize(value, el));
      },
      unbind: function (el) {
        el.iFrameResizer.removeListeners();
      },
    },
  },
  data() {
    return {
      isReady: false,
      METABASE_SITE_URL: "",
      METABASE_SECRET_KEY: "",
      iframeUrl: "",
      payload_cad: null,
      msg: "",
      optionsTimer: [
        { value: "0", label: "Desligado" },
        { value: "1", label: "1 minuto" },
        { value: "5", label: "5 minutos" },
        { value: "10", label: "10 minutos" },
        { value: "15", label: "15 minutos" },
        { value: "30", label: "30 minutos" },
        { value: "60", label: "60 minutos" },
      ],
      timerSelected: { value: "5", label: "5 minutos" }
    };
  },
  created() {
    _ipermission
      .find(this.$route.params.id)
      .then((res) => {
        if (res.content) {
          this.METABASE_SECRET_KEY = res.content.secret_key;
          this.METABASE_SITE_URL = res.content.endpoint;
          this.payload_cad = res.content.payload2;
        } else {
          this.msg = "Relatorio sem parametros cadastrados.";
        }
      })
      .catch((error) => this.$utils.toastError("Notificação", error))
      .finally(() => {
        this.isReady = true;
      });
  },
  methods: {
    getDashboard(skin, timerSelected) {
      var payload2 = JSON.parse(this.payload_cad);
      payload2.exp = Math.round(Date.now() / 1000) + 120 * 60;
      var token = jwt.sign(payload2, this.METABASE_SECRET_KEY);
      var theme = skin === "dark" ? "&theme=night" : "";
      var time_refresh = 0;
      if (timerSelected && timerSelected.value && timerSelected.value != 0) {
        time_refresh = timerSelected.value * 60;
      }

      this.iframeUrl =
        this.METABASE_SITE_URL +
        "/embed/dashboard/" +
        token +
        "#bordered=false&titled=false" +
        theme +
        (time_refresh > 0 ? `&refresh=${time_refresh}` : "");

      return this.iframeUrl;
    },
    onChangeTimer(option) {
      this.timerSelected = option;
      this.isReady = false;
      setTimeout(() => {
        this.isReady = true;
      }, 500);
    },
  },
};
</script>